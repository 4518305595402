/* eslint-disable eag/match-named-export */
import {createContext, TestIdProps} from 'shared';

type ChoiceContext = TestIdProps & {
  hasOptionCheckbox?: boolean;
  'data-name'?: string;
};

export const [ChoiceContextProvider, useChoice] = createContext<ChoiceContext>({
  strict: false,
  name: 'ChoiceContext',
});
