/* eslint-disable eag/match-named-export */
import {createContext} from 'shared';

import {HttpClient} from '../utils/httpClient';

type ProviderConfig = {
  httpClient: HttpClient;
};

export const [Provider, useConfig] = createContext<ProviderConfig>({
  name: 'DynamicUiContext',
  strict: false,
});
