import {captureException} from '@sentry/browser';
import flagsmith from 'flagsmith';
import {ITraits} from 'flagsmith/types';

import {useCallback, useEffect, useRef, useState} from 'react';

import {isNil, isNotNil} from 'ramda';

import {browserStorageKey} from '@dms/config';
import {environment} from '@dms/environment';

interface UseFlagSmithProps {
  environmentID: string;
  identity?: string;
  apiUrl: string;
  skip?: boolean;
  traits?: ITraits;
}

const isDev = environment.ENV_TYPE === 'dev';

export function useFlagsmith({environmentID, identity, apiUrl, skip, traits}: UseFlagSmithProps) {
  const [isFlagSmithInitialized, setIsInitialized] = useState<boolean>(false);

  const isDevSettingsMockingFlagsmithFlags =
    isDev && isNotNil(localStorage.getItem(browserStorageKey.MOCK_FLAGS_KEY));

  const handleInitFlagsmith = useCallback(
    (userIdentity?: string, newTraits?: ITraits) => {
      flagsmith
        .init({
          environmentID,
          api: apiUrl,
          identity: userIdentity,
          preventFetch: isDevSettingsMockingFlagsmithFlags,
          cacheFlags: true,
          traits: newTraits,
        })
        .then(() => setIsInitialized(true))
        .catch((error) => {
          // Flagsmith sometimes throws an error with message 'No error message' without any other information
          if (error?.message !== 'No error message' || isNil(error?.message)) {
            captureException(error);
          }
          setIsInitialized(false);
        });
    },
    [environmentID, apiUrl]
  );

  useOnMount(() => {
    if (skip) {
      return;
    }
    handleInitFlagsmith(identity, traits);
  });

  return {isFlagSmithInitialized, handleInitFlagsmith};
}

const useOnMount = (func?: () => void) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current === false) {
      func?.();
      didMountRef.current = true;
    }

    return () => {
      didMountRef.current = false;
    };
  }, []);
};
