/* eslint-disable eag/match-named-export */
import {createContext} from 'shared';

interface PortalManagerContext {
  zIndex?: number;
}

export const [PortalManagerContextProvider, usePortalManager] =
  createContext<PortalManagerContext | null>({
    strict: false,
    name: 'PortalManagerContext',
  });
